import request from '@/utils/request'

export function search (params) {
  return request({
    url: '/leads/search',
    method: 'post',
    params
  })
}

export function getPage (page, noitems, params) {
  return request({
    url: '/leads/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function exportExcel (data) {
  return request({
    url: '/leads/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
